<template>
    <Toast :autoZIndex="false" style="z-index: 15000" />
    <ConfirmDialog />
    <DynamicDialog />
</template>

<script>
    export default {
    
    }
</script>